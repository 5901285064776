import Vue from "vue";

export const roundToNearest = (number: number, nearest: number) => {
  return (Math.round(number / nearest) * nearest).toFixed(nearest < 1 ? 2 : 0);
};

export const currencyFormatter = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });

export const formatNumber = (value: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 0) => {
  return new Intl.NumberFormat("de-DE", { maximumFractionDigits, minimumFractionDigits }).format(value);
};

Vue.filter("formatCurrency", (value: number) => {
  return currencyFormatter.format(value);
});

Vue.filter("formatNumber", (value: number, minimumFractionDigits = 0, maximumFractionDigits: number = 2) => {
  return formatNumber(value, minimumFractionDigits, maximumFractionDigits);
});

Vue.filter("roundToNearest", roundToNearest);
