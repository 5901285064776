//@ts-nocheck
import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import de from "vee-validate/dist/locale/de.json";
import Vue from "vue";

//tslint-disable


Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);



de.messages.min_value = "{_field_} muss mindestens {min}m² betragen";
de.messages.construction_year = "{_field_} muss neuer als {min} sein";
localize("de", de);
extend("required", required);