/* tslint:disable */
/* eslint-disable */
/**
 * MaGic API
 * API docs for MaGic
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";
import { LatLngBoundsExpression } from "leaflet";

/**
 *
 * @export
 * @interface AreaDto
 */
export interface AreaDto {
  /**
   *
   * @type {string}
   * @memberof AreaDto
   */
  kgs: string;
  /**
   *
   * @type {string}
   * @memberof AreaDto
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof AreaDto
   */
  population: number;
  /**
   *
   * @type {string}
   * @memberof AreaDto
   */
  parentKgs: string;
  /**
   *
   * @type {object}
   * @memberof AreaDto
   */
  geometry: object;
  /**
   *
   * @type {object}
   * @memberof AreaDto
   */
  centroid: object;
  /**
   *
   * @type {number}
   * @memberof AreaDto
   */
  offersAppartmentBuy: number;
  /**
   *
   * @type {number}
   * @memberof AreaDto
   */
  offersAppartmentRent: number;
  /**
   *
   * @type {number}
   * @memberof AreaDto
   */
  offersHouseBuy: number;
  /**
   *
   * @type {number}
   * @memberof AreaDto
   */
  offersHouseRent: number;
  /**
   *
   * @type {number}
   * @memberof AreaDto
   */
  offers: number;
  /**
   *
   * @type {string}
   * @memberof AreaDto
   */
  licensePlate: string;
  /**
   *
   * @type {string}
   * @memberof AreaDto
   */
  district: string;
  /**
   *
   * @type {AreaType}
   * @memberof AreaDto
   */
  areaType: AreaType;

  /**
   *
   * @type {boolean}
   * @memberof AreaDto
   */
  hovered: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AreaState {
  inProgress = "inProgress",
  inPreparation = "inPreparation",
  approved = "approved",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum AreaType {
  kgs2 = "kgs2",
  kgs5 = "kgs5",
  vgs = "vgs",
  plz = "plz",
  kgs8 = "kgs8",
  kgs11 = "kgs11",
  kgs14 = "kgs14",
}

/**
 *
 * @export
 * @interface CreateMarketAreaDto
 */
export interface CreateMarketAreaDto {
  /**
   *
   * @type {Array<CreateMarketAreaGroupDto>}
   * @memberof CreateMarketAreaDto
   */
  marketAreaGroups: Array<CreateMarketAreaGroupDto>;
  /**
   *
   * @type {string}
   * @memberof CreateMarketAreaDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreateMarketAreaDto
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof CreateMarketAreaDto
   */
  customerName: string;
  /**
   *
   * @type {AreaType}
   * @memberof CreateMarketAreaDto
   */
  activeArea: AreaType;
}
/**
 *
 * @export
 * @interface CreateMarketAreaGroupDto
 */
export interface CreateMarketAreaGroupDto {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateMarketAreaGroupDto
   */
  areas: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateMarketAreaGroupDto
   */
  title: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateMarketAreaGroupDto
   */
  isDefault: boolean;
}
/**
 *
 * @export
 * @interface GetMarketAreaGroupDto
 */
export interface GetMarketAreaGroupDto {
  /**
   *
   * @type {string}
   * @memberof GetMarketAreaGroupDto
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof GetMarketAreaGroupDto
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetMarketAreaGroupDto
   */
  isDefault?: boolean;
  /**
   *
   * @type {Array<AreaDto>}
   * @memberof GetMarketAreaGroupDto
   */
  selectedAreas: Array<AreaDto>;
}
/**
 *
 * @export
 * @interface ListMarketAreaGroupDto
 */
export interface ListMarketAreaGroupDto {
  /**
   *
   * @type {Array<string>}
   * @memberof ListMarketAreaGroupDto
   */
  selectedAreas: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ListMarketAreaGroupDto
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof ListMarketAreaGroupDto
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ListMarketAreaGroupDto
   */
  isDefault?: boolean;
}
/**
 *
 * @export
 * @interface MarketAreaGetDto
 */
export interface MarketAreaGetDto {
  /**
   *
   * @type {number}
   * @memberof MarketAreaGetDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MarketAreaGetDto
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaGetDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaGetDto
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaGetDto
   */
  customerName: string;
  /**
   *
   * @type {AreaType}
   * @memberof MarketAreaGetDto
   */
  areaType: AreaType;
  /**
   *
   * @type {AreaState}
   * @memberof MarketAreaGetDto
   */
  areaState: AreaState;
  /**
   *
   * @type {LatLngBoundsExpression}
   * @memberof MarketAreaGetDto
   */
  boundingBox: LatLngBoundsExpression;
  /**
   *
   * @type {Array<GetMarketAreaGroupDto>}
   * @memberof MarketAreaGetDto
   */
  marketAreaGroups: Array<GetMarketAreaGroupDto>;
}
/**
 *
 * @export
 * @interface MarketAreaListDto
 */
export interface MarketAreaListDto {
  /**
   *
   * @type {number}
   * @memberof MarketAreaListDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MarketAreaListDto
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaListDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaListDto
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaListDto
   */
  customerName: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaListDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof MarketAreaListDto
   */
  updatedAt: string;
  /**
   *
   * @type {Array<ListMarketAreaGroupDto>}
   * @memberof MarketAreaListDto
   */
  marketAreaGroups: Array<ListMarketAreaGroupDto>;
  /**
   *
   * @type {AreaState}
   * @memberof MarketAreaListDto
   */
  areaState: AreaState;
}
/**
 *
 * @export
 * @interface PaginatedMarketAreaListDto
 */
export interface PaginatedMarketAreaListDto {
  /**
   *
   * @type {Array<MarketAreaListDto>}
   * @memberof PaginatedMarketAreaListDto
   */
  items: Array<MarketAreaListDto>;
  /**
   *
   * @type {PaginationMetadata}
   * @memberof PaginatedMarketAreaListDto
   */
  meta: PaginationMetadata;
}
/**
 *
 * @export
 * @interface PaginationMetadata
 */
export interface PaginationMetadata {
  /**
   *
   * @type {number}
   * @memberof PaginationMetadata
   */
  itemCount: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMetadata
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMetadata
   */
  itemsPerPage: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMetadata
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMetadata
   */
  currentPage: number;
}
/**
 *
 * @export
 * @interface UpdateMarketAreaDto
 */
export interface UpdateMarketAreaDto {
  /**
   *
   * @type {Array<CreateMarketAreaGroupDto>}
   * @memberof UpdateMarketAreaDto
   */
  marketAreaGroups?: Array<CreateMarketAreaGroupDto>;
  /**
   *
   * @type {string}
   * @memberof UpdateMarketAreaDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMarketAreaDto
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMarketAreaDto
   */
  customerName?: string;
  /**
   *
   * @type {AreaType}
   * @memberof UpdateMarketAreaDto
   */
  activeArea?: AreaType;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetPrices: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/prices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetProtected: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/protected`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetPrices(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetPrices(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetProtected(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetProtected(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetPrices(options?: any): AxiosPromise<void> {
      return localVarFp
        .appControllerGetPrices(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetProtected(options?: any): AxiosPromise<void> {
      return localVarFp
        .appControllerGetProtected(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetPrices(options?: any) {
    return DefaultApiFp(this.configuration)
      .appControllerGetPrices(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetProtected(options?: any) {
    return DefaultApiFp(this.configuration)
      .appControllerGetProtected(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GeometryApi - axios parameter creator
 * @export
 */
export const GeometryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary retrieve areas in region
     * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
     * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
     * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerGetAreas: async (
      areaType: "kgs2" | "kgs5" | "vgs" | "plz" | "kgs8" | "kgs11" | "kgs14",
      bounds: Array<number>,
      previousBounds?: Array<number>,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'areaType' is not null or undefined
      assertParamExists("geometryControllerGetAreas", "areaType", areaType);
      // verify required parameter 'bounds' is not null or undefined
      assertParamExists("geometryControllerGetAreas", "bounds", bounds);
      const localVarPath = `/api/v1/geometry/areas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (areaType !== undefined) {
        localVarQueryParameter["areaType"] = areaType;
      }

      if (bounds) {
        localVarQueryParameter["bounds"] = bounds;
      }

      if (previousBounds) {
        localVarQueryParameter["previousBounds"] = previousBounds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeometryApi - functional programming interface
 * @export
 */
export const GeometryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GeometryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary retrieve areas in region
     * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
     * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
     * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async geometryControllerGetAreas(
      areaType: "kgs2" | "kgs5" | "vgs" | "plz" | "kgs8" | "kgs11" | "kgs14",
      bounds: Array<number>,
      previousBounds?: Array<number>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.geometryControllerGetAreas(
        areaType,
        bounds,
        previousBounds,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * GeometryApi - factory interface
 * @export
 */
export const GeometryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GeometryApiFp(configuration);
  return {
    /**
     *
     * @summary retrieve areas in region
     * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
     * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
     * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerGetAreas(
      areaType: "kgs2" | "kgs5" | "vgs" | "plz" | "kgs8" | "kgs11" | "kgs14",
      bounds: Array<number>,
      previousBounds?: Array<number>,
      options?: any,
    ): AxiosPromise<Array<AreaDto>> {
      return localVarFp
        .geometryControllerGetAreas(areaType, bounds, previousBounds, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GeometryApi - object-oriented interface
 * @export
 * @class GeometryApi
 * @extends {BaseAPI}
 */
export class GeometryApi extends BaseAPI {
  /**
   *
   * @summary retrieve areas in region
   * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
   * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
   * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeometryApi
   */
  public geometryControllerGetAreas(
    areaType: "kgs2" | "kgs5" | "vgs" | "plz" | "kgs8" | "kgs11" | "kgs14",
    bounds: Array<number>,
    previousBounds?: Array<number>,
    options?: any,
  ) {
    return GeometryApiFp(this.configuration)
      .geometryControllerGetAreas(areaType, bounds, previousBounds, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MarketareaApi - axios parameter creator
 * @export
 */
export const MarketareaApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateMarketAreaDto} createMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerCreate: async (
      createMarketAreaDto: CreateMarketAreaDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createMarketAreaDto' is not null or undefined
      assertParamExists(
        "marketAreasControllerCreate",
        "createMarketAreaDto",
        createMarketAreaDto,
      );
      const localVarPath = `/api/v1/marketareas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMarketAreaDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerExportMarketAreaById: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("marketAreasControllerExportMarketAreaById", "id", id);
      const localVarPath = `/api/v1/marketareas/{id}/export`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerFindAll: async (
      sort: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sort' is not null or undefined
      assertParamExists("marketAreasControllerFindAll", "sort", sort);
      const localVarPath = `/api/v1/marketareas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerFindOne: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("marketAreasControllerFindOne", "id", id);
      const localVarPath = `/api/v1/marketareas/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerFindOneWithDetailedGeometry: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "marketAreasControllerFindOneWithDetailedGeometry",
        "id",
        id,
      );
      const localVarPath = `/api/v1/marketareas/{id}/detail`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerRemove: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("marketAreasControllerRemove", "id", id);
      const localVarPath = `/api/v1/marketareas/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMarketAreaDto} updateMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerUpdate: async (
      id: string,
      updateMarketAreaDto: UpdateMarketAreaDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("marketAreasControllerUpdate", "id", id);
      // verify required parameter 'updateMarketAreaDto' is not null or undefined
      assertParamExists(
        "marketAreasControllerUpdate",
        "updateMarketAreaDto",
        updateMarketAreaDto,
      );
      const localVarPath = `/api/v1/marketareas/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMarketAreaDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerUpdateState: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("marketAreasControllerUpdateState", "id", id);
      const localVarPath = `/api/v1/marketareas/{id}/state`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MarketareaApi - functional programming interface
 * @export
 */
export const MarketareaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MarketareaApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {CreateMarketAreaDto} createMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerCreate(
      createMarketAreaDto: CreateMarketAreaDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerCreate(
        createMarketAreaDto,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerExportMarketAreaById(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MarketAreaGetDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerExportMarketAreaById(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerFindAll(
      sort: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedMarketAreaListDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerFindAll(
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerFindOne(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MarketAreaGetDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerFindOne(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerFindOneWithDetailedGeometry(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MarketAreaGetDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerFindOneWithDetailedGeometry(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerRemove(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerRemove(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMarketAreaDto} updateMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerUpdate(
      id: string,
      updateMarketAreaDto: UpdateMarketAreaDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerUpdate(
        id,
        updateMarketAreaDto,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketAreasControllerUpdateState(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerUpdateState(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MarketareaApi - factory interface
 * @export
 */
export const MarketareaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MarketareaApiFp(configuration);
  return {
    /**
     *
     * @param {CreateMarketAreaDto} createMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerCreate(
      createMarketAreaDto: CreateMarketAreaDto,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .marketAreasControllerCreate(createMarketAreaDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerExportMarketAreaById(
      id: string,
      options?: any,
    ): AxiosPromise<MarketAreaGetDto> {
      return localVarFp
        .marketAreasControllerExportMarketAreaById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerFindAll(
      sort: string,
      options?: any,
    ): AxiosPromise<PaginatedMarketAreaListDto> {
      return localVarFp
        .marketAreasControllerFindAll(sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerFindOne(
      id: string,
      options?: any,
    ): AxiosPromise<MarketAreaGetDto> {
      return localVarFp
        .marketAreasControllerFindOne(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerFindOneWithDetailedGeometry(
      id: string,
      options?: any,
    ): AxiosPromise<MarketAreaGetDto> {
      return localVarFp
        .marketAreasControllerFindOneWithDetailedGeometry(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerRemove(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .marketAreasControllerRemove(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMarketAreaDto} updateMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerUpdate(
      id: string,
      updateMarketAreaDto: UpdateMarketAreaDto,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .marketAreasControllerUpdate(id, updateMarketAreaDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketAreasControllerUpdateState(
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .marketAreasControllerUpdateState(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MarketareaApi - object-oriented interface
 * @export
 * @class MarketareaApi
 * @extends {BaseAPI}
 */
export class MarketareaApi extends BaseAPI {
  /**
   *
   * @param {CreateMarketAreaDto} createMarketAreaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerCreate(
    createMarketAreaDto: CreateMarketAreaDto,
    options?: any,
  ) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerCreate(createMarketAreaDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerExportMarketAreaById(id: string, options?: any) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerExportMarketAreaById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} sort
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerFindAll(sort: string, options?: any) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerFindAll(sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerFindOne(id: string, options?: any) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerFindOne(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerFindOneWithDetailedGeometry(
    id: string,
    options?: any,
  ) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerFindOneWithDetailedGeometry(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerRemove(id: string, options?: any) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdateMarketAreaDto} updateMarketAreaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerUpdate(
    id: string,
    updateMarketAreaDto: UpdateMarketAreaDto,
    options?: any,
  ) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerUpdate(id, updateMarketAreaDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketareaApi
   */
  public marketAreasControllerUpdateState(id: string, options?: any) {
    return MarketareaApiFp(this.configuration)
      .marketAreasControllerUpdateState(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
