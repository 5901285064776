import { Module } from "vuex";
import { AreaDto, AreaType } from "../../api/types";
import { add, set } from "../helpers";
import { actions } from "./actions";
import {
  GroupedArea,
  MarketAreaGetters,
  MarketAreaMutations,
  MarketAreaState,
  RootState,
} from "./types";

export const marketAreaModule: Module<RootState, any> = {
  namespaced: true,
  state: {
    currentMap: "iibmap",
    activeLayer: "pricelayer_hk",
    activeArea: AreaType.kgs8,
    previousBounds: null,
    currentBounds: null,
    currentZoom: null,
    previousZoom: null,
    loadAreas: false,
    showDetail: false,
    showTraffic: false,
    showEvalAmount: true,
    additionalVisibleLayers: [],
    additionalVisibleAreas: [],
    detailAreas: [],
    areas: [],
    marketAreaGroups: [],
    initialMarkedArea: null,
    loadingInitialArea: false,
  },
  mutations: {
    [MarketAreaMutations.setCurrentMap]: set(MarketAreaState.currentMap),
    [MarketAreaMutations.setActiveLayer]: set(MarketAreaState.activeLayer),
    [MarketAreaMutations.setActiveArea]: set(MarketAreaState.activeArea),
    [MarketAreaMutations.setPreviousBounds]: set(
      MarketAreaState.previousBounds,
    ),
    [MarketAreaMutations.setPreviousZoom]: set(MarketAreaState.previousZoom),
    [MarketAreaMutations.setCurrentBounds]: set(MarketAreaState.currentBounds),
    [MarketAreaMutations.setCurrentZoom]: set(MarketAreaState.currentZoom),
    [MarketAreaMutations.setLoadAreas]: set(MarketAreaState.loadAreas),
    [MarketAreaMutations.setAreas]: set(MarketAreaState.areas),
    [MarketAreaMutations.setMarketAreaGroups]: set(
      MarketAreaState.marketAreaGroups,
    ),
    [MarketAreaMutations.addMarketAreaGroups]: add(
      MarketAreaState.marketAreaGroups,
    ),
    [MarketAreaMutations.setInitialMarkedArea]: set(
      MarketAreaState.initialMarkedArea,
    ),
    [MarketAreaMutations.setLoadingInitialArea]: set(
      MarketAreaState.loadingInitialArea,
    ),
    [MarketAreaMutations.setShowTraffic]: set(MarketAreaState.showTraffic),
    [MarketAreaMutations.setShowEvalAmount]: set(
      MarketAreaState.showEvalAmount,
    ),
    [MarketAreaMutations.setAdditionalVisibleLayers]: set(
      MarketAreaState.additionalVisibleLayers,
    ),
    [MarketAreaMutations.setAdditionalVisibleAreas]: set(
      MarketAreaState.additionalVisibleAreas,
    ),
    [MarketAreaMutations.addAdditionalVisibleAreas]: set(
      MarketAreaState.additionalVisibleAreas,
    ),
    [MarketAreaMutations.setShowDetail]: set(MarketAreaState.showDetail),
    [MarketAreaMutations.setDetailAreas]: set(MarketAreaState.detailAreas),
  },

  actions: actions,
  getters: {
    [MarketAreaGetters.unselectedAreas]: (state: RootState, getters) => {
      const areas = getters[MarketAreaGetters.areasWithGroup] as GroupedArea[];
      return state.areas.filter(
        (a: AreaDto) => !areas.find((b: GroupedArea) => b.area.kgs === a.kgs),
      );
    },
    [MarketAreaGetters.areasWithGroup]: (state: RootState) => {
      const groupedAreas = state.marketAreaGroups
        .map((val) => {
          return val.selectedAreas.map((area) => {
            return {
              area,
              group: {
                title: val.title,
                id: val.id,
                isDefault: val.isDefault,
              },
            } as GroupedArea;
          });
        })
        .reduce((l: GroupedArea[], r: GroupedArea[]) => r.concat(l), []);

      const activeType = groupedAreas.filter(
        (groupedArea: GroupedArea) =>
          groupedArea.area.areaType === state.activeArea,
      );
      const inactiveType = groupedAreas.filter(
        (groupedArea: GroupedArea) =>
          groupedArea.area.areaType !== state.activeArea,
      );
      return [...inactiveType, ...activeType];
    },

    [MarketAreaGetters.marketAreaGroupById]: (state: RootState) => (
      id: number,
    ) => state.marketAreaGroups.find((g) => g.id === id),
    [MarketAreaGetters.marketAreaGroups]: (state: RootState) =>
      state.marketAreaGroups,
    [MarketAreaGetters.loadAreas]: (state: RootState) => state.loadAreas,
  },
};
