import {
  AreaDto,
  AreaType,
  CreateMarketAreaDto,
  GetMarketAreaGroupDto,
  MarketAreaGetDto,
  UpdateMarketAreaDto,
} from "../../api/types";

export const AreaTypeMapBoundaries = {
  [AreaType.kgs2]: {
    minZoom: 5,
    initialZoom: 7,
  },
  [AreaType.kgs5]: {
    minZoom: 8,
    initialZoom: 10,
  },
  [AreaType.vgs]: {
    minZoom: 9,
    initialZoom: 11,
  },
  [AreaType.plz]: {
    minZoom: 9,
    initialZoom: 11,
  },
  [AreaType.kgs8]: {
    minZoom: 9,
    initialZoom: 11,
  },
  [AreaType.kgs11]: {
    minZoom: 8,
    initialZoom: 9,
  },
  [AreaType.kgs14]: {
    minZoom: 8,
    initialZoom: 9,
  },
};

export const AreaTypeMapWeight = {
  [AreaType.kgs2]: 3.7,
  [AreaType.kgs5]: 2.8,
  [AreaType.vgs]: 1.6,
  [AreaType.plz]: 1.6,
  [AreaType.kgs8]: 2.4,
  [AreaType.kgs11]: 1.2,
  [AreaType.kgs14]: 1.0,
};

export enum MarketAreaActions {
  updateMapBounds = "updateMapBounds",
  updateMapZoom = "updateMapZoom",
  updateActiveArea = "updateActiveArea",
  createMarketAreaGroup = "createMarketAreaGroup",
  deleteMarketAreaGroup = "deleteMarketAreaGroup",
  moveAreasBetweenGroups = "moveAreasBetweenGroups",

  moveAreasToGroup = "moveAreasToGroup",
  renameMarketAreaGroup = "renameMarketAreaGroup",
  getAreas = "getAreas",
  loadAdditionalVisibleAreas = "loadAdditionalVisibleAreas",
  loadAllAdditionalVisibleLayers = "loadAllAdditionalVisibleLayers",
  removeAdditionalVisibleAreas = "removeAdditionalVisibleAreas",
  saveMarketArea = "saveMarketArea",
  toggleSelectedArea = "toggleSelectedArea",
  toggleHoverArea = "toggleHoverArea",
  addToSelectedArea = "addToSelectedArea",
  clearAll = "clearAll",
  getInitialMarkedArea = "getInitialMarkedArea",
  toggleAdditionalVisibleLayer = "toggleAdditionalVisibleLayer",
  showDetailView = "showDetailView",
}

export enum MarketAreaGetters {
  unselectedAreas = "unselectedAreas",
  selectedAreas = "selectedAreas",
  marketAreaGroups = "marketAreaGroups",
  marketAreaGroupById = "marketAreaGroupById",
  areasWithGroup = "areasWithGroup",
  loadAreas = "loadAreas",
}

export enum MarketAreaMutations {
  setCurrentMap = "setCurrentMap",
  setActiveLayer = "setActiveLayer",
  setActiveArea = "setActiveArea",
  setPreviousBounds = "setPreviousBounds",
  setPreviousZoom = "setPreviousZoom",
  setCurrentBounds = "setCurrentBounds",
  setCurrentZoom = "setCurrentZoom",
  setLoadAreas = "setLoadAreas",
  setAreas = "setAreas",
  setMarketAreaGroups = "setMarketAreaGroups",
  setInitialMarkedArea = "setInitialMarkedArea",
  setLoadingInitialArea = "setLoadingInitialArea",
  addMarketAreaGroups = "addMarketAreaGroups",
  setShowTraffic = "setShowTraffic",
  setShowEvalAmount = "setShowEvalAmount",
  setAdditionalVisibleLayers = "setAdditionalVisibleLayers",
  setAdditionalVisibleAreas = "setAdditionalVisibleAreas",
  addAdditionalVisibleAreas = "addAdditionalVisibleAreas",
  setShowDetail = "setShowDetail",
  setDetailAreas = "setDetailAreas",
}

export enum MarketAreaState {
  currentMap = "currentMap",
  showTraffic = "showTraffic",
  showEvalAmount = "showEvalAmount",
  activeLayer = "activeLayer",
  activeArea = "activeArea",
  additionalVisibleLayers = "additionalVisibleLayers",
  additionalVisibleAreas = "additionalVisibleAreas",
  previousBounds = "previousBounds",
  currentBounds = "currentBounds",
  previousZoom = "previousZoom",
  currentZoom = "currentZoom",
  loadAreas = "loadAreas",
  areas = "areas",
  marketAreaGroups = "marketAreaGroups",
  initialMarkedArea = "initialMarkedArea",
  loadingInitialArea = "loadingInitialArea",
  showDetail = "showDetail",
  detailAreas = "detailAreas",
}
export type RootState = {
  currentMap: string;
  showTraffic: boolean;
  showEvalAmount: boolean;
  showDetail: boolean;
  activeLayer: string;
  activeArea: AreaType;
  previousBounds: GeoJSON.BBox | null;
  currentBounds: GeoJSON.BBox | null;
  previousZoom: number | null;
  currentZoom: number | null;
  loadAreas: boolean;
  areas: AreaDto[];
  additionalVisibleLayers: AreaType[];
  additionalVisibleAreas: AreaDto[];
  detailAreas: GroupedArea[];
  marketAreaGroups: GetMarketAreaGroupDto[];
  initialMarkedArea: MarketAreaGetDto | null;
  loadingInitialArea: boolean;
};
export type Group = { id?: number; title: string; isDefault?: boolean };

export type GroupedArea = {
  area: AreaDto;
  group: Group;
};

export type SaveAreaPayload = {
  id?: number;
  marketArea: CreateMarketAreaDto | UpdateMarketAreaDto;
};
