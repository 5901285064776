import { Module } from "vuex";
import { marketAreaResource } from "../../api/resources";
import {
  MarketareaApiAxiosParamCreator,
  MarketAreaGetDto,
  MarketAreaListDto,
  PaginatedMarketAreaListDto,
} from "../../api/types";
import { set } from "../helpers";

export enum MarketAreaOverviewActions {
  load = "load",
  loadById = "loadById",
  excelExport = "excelExport",
}

export enum MarketAreaOverviewMutations {
  setMarketAreas = "setMarketAreas",
}

type MarketAreasState = {
  marketAreas: MarketAreaListDto[];
};

export interface Customer {
  id: string;
  companyName: string;
}

export const marketAreaOverviewModule: Module<MarketAreasState, any> = {
  namespaced: true,
  state: {
    marketAreas: [],
  },
  mutations: {
    [MarketAreaOverviewMutations.setMarketAreas]: set("marketAreas"),
  },
  actions: {
    async [MarketAreaOverviewActions.load](
      { commit },
      payload: {
        page: number;
        items: number;
        sortBy?: keyof MarketAreaListDto;
        sort?: "ASC" | "DESC";
        searchQuery?: string;
      },
    ) {
      const marketAreas = (
        await marketAreaResource.get<PaginatedMarketAreaListDto>(
          `?page=${payload.page}&limit=${payload.items}&sort=${
            payload.sort
          }&sortBy=${payload.sortBy || "id"}&searchQuery=${
            payload.searchQuery
          }`,
        )
      ).data;
      commit(MarketAreaOverviewMutations.setMarketAreas, marketAreas.items);
      return marketAreas;
    },
    async [MarketAreaOverviewActions.loadById]({ commit }, id) {
      const marketAreas = (
        await marketAreaResource.get<MarketAreaGetDto>(`/${id}`)
      ).data;
    },
    async [MarketAreaOverviewActions.excelExport](_, id) {
      await marketAreaResource
        .get(`/${id}/export`, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "marktgebiet.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
