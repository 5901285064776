import axios, { AxiosResponse } from "axios";
import { Prices } from "../store";
import { Customer } from "../store/marketareaoverview";
import { authMiddleWare } from "./auth-middleware";
import {
  AreaDto,
  AreaType,
  CreateMarketAreaDto,
  UpdateMarketAreaDto,
} from "./types";

const BASE_URL = `${process.env.VUE_APP_BASE_URL || ""}/api/v1`;

export const areasResource = axios.create({
  baseURL: `${BASE_URL}/geometry`,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

export const getAreas = (
  kgsType: AreaType,
  previousBounds: GeoJSON.BBox | null,
  bounds: GeoJSON.BBox | null,
) =>
  areasResource.get<AreaDto[]>("/areas", {
    params: { areaType: kgsType, bounds, previousBounds },
  });

export const getArea = (kgsType: AreaType, kgs: string) =>
  areasResource.get<AreaDto>(`/area/${kgs}`, {
    params: { areaType: kgsType },
  });

export const getAreaList = (areasList: { areaType: AreaType; kgs: string }[]) =>
  areasResource.post<AreaDto[]>(`/area`, areasList);

export const priceResource = axios.create({
  baseURL: `${BASE_URL}/prices`,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

export const getPrices = () => priceResource.get<Prices>("");

export const marketAreaResource = axios.create({
  baseURL: `${BASE_URL}/marketareas`,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

marketAreaResource.interceptors.request.use(authMiddleWare);

export const createMarketArea = (marketArea: CreateMarketAreaDto) =>
  marketAreaResource.post("", marketArea);

export const updateMarketArea = (id: number, marketArea: UpdateMarketAreaDto) =>
  marketAreaResource.patch("" + id, marketArea);

export const adminCustomerResource = axios.create({
  baseURL: `https://admin-api.iib-it.de/1.0/customers/customers`,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

export const customerResource = axios.create({
  baseURL: `https://api.iib-it.de/1.0/customers/customers`,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

adminCustomerResource.interceptors.request.use(authMiddleWare);

export const getCustomerById = async (
  id: string,
): Promise<AxiosResponse<Customer>> => await customerResource.get(`/${id}`);

export const getCustomers = async (query: string) =>
  await adminCustomerResource.get(`?query=${query}`);
