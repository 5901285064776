











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { marketArea } from "../../store";
import { MarketAreaState } from "../../store/marketarea/types";

@Component({})
export default class EvaluationAmountRenderer extends Vue {
  @Prop() value!: number;

  @marketArea.State(MarketAreaState.showTraffic)
  showTraffic!: boolean;

  get classForValue() {
    if (this.value == 10) {
      return "yellow";
    }
    if (this.value > 10) {
      return "green";
    }
    return "red";
  }
}
