/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { authGuard } from "../components/auth/authGuard";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/components/dashboard/Layout.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/area",
    name: "create-area",
    component: () =>
      import(
        /* webpackChunkName: "marketarea" */ "@/components/marketarea/Layout.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/area/:id",
    name: "edit-area",
    component: () =>
      import(
        /* webpackChunkName: "marketarea" */ "@/components/marketarea/Layout.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/print-area/:id",
    name: "print-area",
    component: () =>
      import(
        /* webpackChunkName: "marketarea" */ "@/components/printmap/Layout.vue"
      ),
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  await (store as any).restored;
  next();
});

export default router;
