import Vue from "vue";
import createAuth0Client, {
  PopupLoginOptions,
  Auth0Client,
  RedirectLoginOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from "@auth0/auth0-spa-js";
import Component from "vue-class-component";
import { User } from "./User";
import { Token } from "./Token";

export type Auth0Options = {
  domain: string;
  clientId: string;
  audience?: string;
  [key: string]: string | undefined;
};

export type RedirectCallback = (appState: any) => void;

@Component({})
export class VueAuth extends Vue {
  loading = true;
  isAuthenticated?: boolean = false;
  user!: User | null;
  token: Token = new Token({});
  jwtToken!: string | null;
  auth0Client?: Auth0Client;
  popupOpen = false;
  error?: Error;

  async getUser() {
    return new User(await this.auth0Client?.getUser());
  }

  /** Authenticates the user using a popup window */
  async loginWithPopup(o: PopupLoginOptions) {
    this.popupOpen = true;

    try {
      await this.auth0Client?.loginWithPopup(o);
    } catch (e) {
      console.error(e);
      this.error = e;
    } finally {
      this.popupOpen = false;
    }
    window.localStorage.setItem("auth", "1");
    this.user = await this.getUser();
    this.isAuthenticated = true;
  }

  /** Authenticates the user using the redirect method */
  loginWithRedirect(o: RedirectLoginOptions) {
    return this.auth0Client?.loginWithRedirect(o);
  }

  /** Returns all the claims present in the ID token */
  getIdTokenClaims(o: GetIdTokenClaimsOptions) {
    return this.auth0Client?.getIdTokenClaims(o);
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  getTokenSilently(o: GetTokenSilentlyOptions) {
    return this.auth0Client?.getTokenSilently(o);
  }

  /** Gets the access token using a popup window */
  getTokenWithPopup(o: GetTokenWithPopupOptions) {
    return this.auth0Client?.getTokenWithPopup(o);
  }

  async getDecodedToken() {
    const jwtToken = await this.auth0Client!.getTokenSilently();
    this.jwtToken = jwtToken;
    let jwtData = jwtToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    return new Token(decodedJwtData);
  }

  /** Logs the user out and removes their session on the authorization server */
  logout(o: LogoutOptions) {
    window.localStorage.removeItem("auth");
    return this.auth0Client?.logout(o);
  }

  /** Use this lifecycle method to instantiate the SDK client */
  async init(
    onRedirectCallback: RedirectCallback,
    redirectUri: string,
    auth0Options: Auth0Options,
  ) {
    // Create a new instance of the SDK client using members of the given options object
    this.auth0Client = await createAuth0Client({
      domain: auth0Options.domain,
      client_id: auth0Options.clientId,
      audience: auth0Options.audience,
      redirect_uri: redirectUri,
    });

    // await this.auth0Client.logout({ returnTo: window.location.origin });
    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes("error=") ||
        (window.location.search.includes("code=") &&
          window.location.search.includes("state="))
      ) {
        // handle the redirect and retrieve tokens
        const {
          appState,
        } = (await this.auth0Client?.handleRedirectCallback()) ?? {
          appState: undefined,
        };

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState);
      }
    } catch (e) {
      // console.error(e);
      this.error = e;
    } finally {
      // Initialize our internal
      // authentication state when the page is reloaded
      try {
        this.isAuthenticated = await this.auth0Client?.isAuthenticated();
        this.user = await this.getUser();
        this.token = await this.getDecodedToken();
      } catch (e) {
      } finally {
        this.loading = false;
      }
    }
  }
}
