import { LatLngBounds } from "leaflet";
import { ActionTree } from "vuex";
import {
  createMarketArea,
  getAreaList,
  getAreas,
  marketAreaResource,
  updateMarketArea,
} from "../../api/resources";
import {
  AreaDto,
  AreaType,
  CreateMarketAreaDto,
  GetMarketAreaGroupDto,
  MarketAreaGetDto,
  UpdateMarketAreaDto,
} from "../../api/types";
import { latLngBoundsToBBox } from "../helpers";
import {
  Group,
  GroupedArea,
  MarketAreaActions,
  MarketAreaGetters,
  MarketAreaMutations,
  RootState,
  SaveAreaPayload,
} from "./types";

export const actions: ActionTree<RootState, any> = {
  [MarketAreaActions.updateMapBounds]: async (
    { commit, dispatch, state },
    payload: LatLngBounds,
  ) => {
    commit(MarketAreaMutations.setPreviousBounds, state.currentBounds);
    const bbox = latLngBoundsToBBox(payload);

    let zoomingIn = false;
    if (state.currentBounds != null) {
      const oldBounds = new LatLngBounds([
        [state.currentBounds[0], state.currentBounds[1]],
        [state.currentBounds[2], state.currentBounds[3]],
      ]);
      zoomingIn = oldBounds.contains(payload);
    }

    commit(MarketAreaMutations.setCurrentBounds, bbox);
    if (!zoomingIn) {
      dispatch(MarketAreaActions.getAreas);
    }
  },

  [MarketAreaActions.updateMapZoom]: async (
    { commit, dispatch, state },
    payload: number,
  ) => {
    commit(MarketAreaMutations.setPreviousZoom, state.currentZoom);
    commit(MarketAreaMutations.setCurrentZoom, payload);
  },

  [MarketAreaActions.createMarketAreaGroup]: ({ commit, state }) => {
    const groups = state.marketAreaGroups;
    // groups.push();
    commit(MarketAreaMutations.addMarketAreaGroups, {
      selectedAreas: [],
      selectedAreasAsArea: [],
      id: new Date().getTime(),
      title: "Neue Gruppe",
    });
  },

  [MarketAreaActions.deleteMarketAreaGroup]: (
    { commit, state },
    id: number,
  ) => {
    const groups = state.marketAreaGroups;
    const groupToDelete = groups.findIndex((group) => group.id === id)!;
    const defaultGroup = groups.find((group) => group.isDefault)!;
    if (groupToDelete !== -1) {
      defaultGroup?.selectedAreas.push(...groups[groupToDelete].selectedAreas);
      groups.splice(groupToDelete, 1);
    }
    commit(MarketAreaMutations.setMarketAreaGroups, groups);
  },

  [MarketAreaActions.moveAreasBetweenGroups]: (
    { commit, state },
    payload: { toGroup: Group; groupedAreas: GroupedArea[] },
  ) => {
    const groupedAreas = state.marketAreaGroups;

    // remove selected from origin group
    for (const groupedArea of payload.groupedAreas) {
      const index = groupedAreas.findIndex(
        (group) => group.id === groupedArea.group.id,
      );
      if (index !== -1) {
        groupedAreas[index].selectedAreas.splice(
          groupedAreas[index].selectedAreas.findIndex(
            (area) => area.kgs == groupedArea.area.kgs,
          ),
          1,
        );
      }
    }
    const moveToGroup = state.marketAreaGroups.findIndex(
      (group) => group.id === payload.toGroup.id,
    )!;
    const areasToAdd = payload.groupedAreas.map((area) => area.area);
    const removedAreas = groupedAreas[moveToGroup].selectedAreas.filter(
      (x) => !areasToAdd.includes(x),
    );

    // remove unselected
    for (const removedArea of removedAreas) {
      groupedAreas[moveToGroup].selectedAreas.splice(
        groupedAreas[moveToGroup].selectedAreas.findIndex(
          (area) => removedArea.kgs === area.kgs,
        ),
        1,
      );
    }
    const defaultGroup = groupedAreas.find((group) => group.isDefault)!;
    defaultGroup.selectedAreas.push(...removedAreas);
    // add to new group
    groupedAreas[moveToGroup].selectedAreas.push(...areasToAdd);

    commit(MarketAreaMutations.setMarketAreaGroups, groupedAreas);
  },

  [MarketAreaActions.moveAreasToGroup]: (
    { commit, state },
    payload: { groupId: number; areas: AreaDto[] },
  ) => {
    const groupedAreas = state.marketAreaGroups;
    const groupIndex = state.marketAreaGroups.findIndex(
      (group) => group.id === payload.groupId,
    )!;
    groupedAreas.forEach((group, index) => {
      groupedAreas[index].selectedAreas = group.selectedAreas.filter(
        (area) => !payload.areas.find((a) => a.kgs === area.kgs),
      );
      if (index === groupIndex) {
        groupedAreas[index].selectedAreas.push(...payload.areas);
      }
    });

    commit(MarketAreaMutations.setMarketAreaGroups, groupedAreas);
  },

  [MarketAreaActions.updateActiveArea]: async (
    { commit, dispatch, state },
    payload: string,
  ) => {
    if (state.activeArea === payload) {
      return;
    }
    commit(MarketAreaMutations.setAreas, []);
    commit(MarketAreaMutations.setAdditionalVisibleLayers, []);
    commit(MarketAreaMutations.setPreviousBounds, null);
    commit(MarketAreaMutations.setPreviousZoom, null);
    commit(MarketAreaMutations.setActiveArea, payload);
    dispatch(MarketAreaActions.getAreas);
  },

  [MarketAreaActions.getAreas]: async ({ commit, state }) => {
    commit(MarketAreaMutations.setLoadAreas, true);
    try {
      const areas = (
        await getAreas(
          state.activeArea,
          state.previousBounds,
          state.currentBounds,
        )
      ).data.filter(
        (element: AreaDto) =>
          !state.areas.find((area) => area.kgs === element.kgs),
      );
      commit(MarketAreaMutations.setAreas, [...state.areas, ...areas]);
    } finally {
      commit(MarketAreaMutations.setLoadAreas, false);
    }
  },

  [MarketAreaActions.loadAdditionalVisibleAreas]: async (
    { commit, state },
    area: AreaType,
  ) => {
    commit(MarketAreaMutations.setLoadAreas, true);
    try {
      const areas = (
        await getAreas(area, null, state.currentBounds)
      ).data.filter(
        (element: AreaDto) =>
          !state.additionalVisibleAreas.find(
            (area) => area.kgs === element.kgs,
          ),
      );

      commit(MarketAreaMutations.addAdditionalVisibleAreas, areas);
    } finally {
      commit(MarketAreaMutations.setLoadAreas, false);
    }
  },

  [MarketAreaActions.removeAdditionalVisibleAreas]: async (
    { commit, state },
    areaType: AreaType,
  ) => {
    const newAreas = state.additionalVisibleAreas.filter(
      (area) => area.areaType !== areaType,
    );

    commit(MarketAreaMutations.setAdditionalVisibleAreas, newAreas);
  },

  [MarketAreaActions.toggleSelectedArea]: async (
    { commit, state },
    payload: { area: AreaDto; delete: boolean },
  ) => {
    const marketAreas = state.marketAreaGroups;
    if (marketAreas.length === 0) {
      marketAreas.push({
        selectedAreas: [],
        title: "Teilmärkte",
        isDefault: true,
      });
    }

    const marketAreaGroup = marketAreas.findIndex((group) =>
      group.selectedAreas.find((a) => a.kgs === payload.area.kgs),
    );

    if (marketAreaGroup !== -1) {
      // selection already present;
      if (payload.delete || state.activeArea == payload.area.areaType) {
        marketAreas[marketAreaGroup].selectedAreas.splice(
          marketAreas[marketAreaGroup].selectedAreas.findIndex(
            (a) => a.kgs === payload.area.kgs,
          ),
          1,
        );
      }
    } else {
      const defaultGroup = marketAreas.find((group) => group.isDefault)!;

      defaultGroup.selectedAreas.push(payload.area);
      // const areaRes = await getArea(payload.area.areaType, payload.area.kgs);
      // defaultGroup.selectedAreas.push(areaRes.data);
    }

    commit(MarketAreaMutations.setMarketAreaGroups, marketAreas);
  },

  [MarketAreaActions.toggleHoverArea]: async (
    { commit, state },
    payload: { area: AreaDto; highlight: boolean },
  ) => {
    const marketAreas = state.marketAreaGroups;

    let groupIndex = marketAreas.findIndex((group) =>
      group.selectedAreas.find((a) => a.kgs === payload.area.kgs),
    );

    if (groupIndex === -1)
      groupIndex = marketAreas.findIndex((group) => group.isDefault)!;

    const areaIndex = marketAreas[groupIndex].selectedAreas.findIndex(
      (a) => a.kgs === payload.area.kgs,
    );

    if (areaIndex === -1) return;

    marketAreas[groupIndex].selectedAreas = marketAreas[
      groupIndex
    ].selectedAreas.map((area, index) =>
      index === areaIndex
        ? { ...area, hovered: payload.highlight }
        : { ...area, hovered: false },
    );

    commit(MarketAreaMutations.setMarketAreaGroups, marketAreas);
  },

  [MarketAreaActions.addToSelectedArea]: (
    { commit, state },
    areasToAdd: AreaDto[],
  ) => {
    const marketAreas = state.marketAreaGroups;

    if (marketAreas.length === 0) {
      marketAreas.push({
        selectedAreas: [],
        title: "Teilmärkte",
        isDefault: true,
      });
    }

    console.log(state.marketAreaGroups, areasToAdd);
    const defaultGroup = marketAreas.findIndex((group) => group.isDefault)!;
    const newAreasToAdd = areasToAdd.filter(
      (area) =>
        !marketAreas[defaultGroup].selectedAreas.find(
          (selection) => selection.kgs === area.kgs,
        ),
    );

    marketAreas[defaultGroup].selectedAreas = marketAreas[
      defaultGroup
    ].selectedAreas.concat(newAreasToAdd);

    commit(MarketAreaMutations.setMarketAreaGroups, marketAreas);
  },

  [MarketAreaActions.clearAll]: ({ commit }) => {
    commit(MarketAreaMutations.setMarketAreaGroups, []);
  },

  [MarketAreaActions.getInitialMarkedArea]: async (
    { commit, dispatch },
    payload: { id: string; detail: boolean },
  ) => {
    commit(MarketAreaMutations.setLoadingInitialArea, true);
    if (payload.id) {
      const initialMarkedArea: MarketAreaGetDto = (
        await marketAreaResource.get<MarketAreaGetDto>(
          payload.id + (payload.detail ? "/detail" : ""),
        )
      ).data;
      commit(
        MarketAreaMutations.setMarketAreaGroups,
        initialMarkedArea.marketAreaGroups,
      );
      if (initialMarkedArea.areaType) {
        commit(MarketAreaMutations.setActiveArea, initialMarkedArea.areaType);
      }
      commit(MarketAreaMutations.setInitialMarkedArea, initialMarkedArea);
    } else {
      commit(MarketAreaMutations.setInitialMarkedArea, null);
    }
    commit(MarketAreaMutations.setLoadingInitialArea, false);
    dispatch(MarketAreaActions.loadAllAdditionalVisibleLayers);
  },

  [MarketAreaActions.saveMarketArea]: async (
    { getters, state },
    payload: SaveAreaPayload,
  ) => {
    const groups = getters[
      MarketAreaGetters.marketAreaGroups
    ] as GetMarketAreaGroupDto[];
    payload.marketArea.marketAreaGroups = groups.map((group) => {
      return {
        title: group.title,
        areas: group.selectedAreas.map((a) => a.kgs),
        isDefault: group.isDefault || false,
      };
    });
    payload.marketArea.activeArea = state.activeArea;
    if (payload.id) {
      await updateMarketArea(
        payload.id,
        payload.marketArea as UpdateMarketAreaDto,
      );
    } else {
      await createMarketArea(payload.marketArea as CreateMarketAreaDto);
    }
  },

  [MarketAreaActions.renameMarketAreaGroup]: (
    { commit, state },
    payload: { id: number; newName: string },
  ) => {
    const groups = state.marketAreaGroups;
    const group = groups.find((g) => g.id === payload.id)!;
    group.title = payload.newName;
    commit(MarketAreaMutations.setMarketAreaGroups, groups);
  },

  [MarketAreaActions.toggleAdditionalVisibleLayer]: (
    { commit, dispatch, state },
    layer: AreaType,
  ) => {
    const layers = [...state.additionalVisibleLayers];
    const index = layers.indexOf(layer);
    if (index === -1) {
      layers.push(layer);
      dispatch(MarketAreaActions.loadAdditionalVisibleAreas, layer);
    } else {
      layers.splice(index, 1);
      dispatch(MarketAreaActions.removeAdditionalVisibleAreas, layer);
    }
    commit(MarketAreaMutations.setAdditionalVisibleLayers, layers);
  },

  [MarketAreaActions.loadAllAdditionalVisibleLayers]: async ({
    dispatch,
    state,
  }) => {
    Promise.all(
      state.additionalVisibleLayers.map((layer) =>
        dispatch(MarketAreaActions.loadAdditionalVisibleAreas, layer),
      ),
    );
  },

  [MarketAreaActions.showDetailView]: async (
    { commit, dispatch, state },
    payload: { detail: boolean },
  ) => {
    if (payload.detail) {
      const areasListGet = state.marketAreaGroups
        .flatMap((group) => group.selectedAreas)
        .map((area) => ({ areaType: area.areaType, kgs: area.kgs }));
      const areasList = await getAreaList(areasListGet);
      const groupedAreas = areasList.data.map((area) => {
        const group = state.marketAreaGroups.find((g) =>
          g.selectedAreas.find((a) => area.kgs === a.kgs),
        );
        return {
          area,
          group: {
            title: group?.title,
            id: group?.id,
            isDefault: group?.isDefault,
          },
        };
      });

      commit(MarketAreaMutations.setDetailAreas, groupedAreas);
    }
    // await dispatch(MarketAreaActions.getInitialMarkedArea, {
    //   detail: payload.detail,
    //   id: state.initialMarkedArea?.id,
    // });
    commit(MarketAreaMutations.setShowDetail, payload.detail);
  },
};
