





































import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";
import { RootActions } from "../../store";

@Component({})
export default class AppBar extends Vue {
  @Action(RootActions.clearAll) clearAll!: () => Promise<void>;

  async logout() {
    await this.clearAll();
    this.$auth.logout({ returnTo: window.location.origin });
  }
}
