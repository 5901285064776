import { LatLngBounds } from "leaflet";
import Vue from "vue";
import Vuex from "vuex";
import { namespace } from "vuex-class";
import VuexPersistence, { AsyncStorage } from "vuex-persist";
import * as localforage from "localforage";
import { getPrices } from "../api/resources";
import { set } from "./helpers";
import { marketAreaOverviewModule } from "./marketareaoverview";
import { marketAreaModule } from "./marketarea";

Vue.use(Vuex);
export const marketArea = namespace("marketArea");
export const marketAreaOverview = namespace("marketAreaOverview");

export interface Prices {
  basePrice: number,
  pricePerUnit: number
}

type RootState = {
  prices: Prices;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: localforage as AsyncStorage,
  asyncStorage: true,
  modules: ["marketArea"],
});


export enum RootMutations {
  setPrices = "setPrices",
}


export enum RootActions {
  getPrices = "getPrices",
  clearAll = "clearAll"
}

const store = new Vuex.Store<RootState>({
  state: {
    prices: {
      basePrice: 3000,
      pricePerUnit: 80
    }
  },
  plugins: [vuexLocal.plugin],

  mutations: {
    [RootMutations.setPrices]: set("prices"),
  },

  actions: {
    [RootActions.getPrices]: async ({ commit }) => {
      commit(RootMutations.setPrices, (await getPrices()).data);
    },

    [RootActions.clearAll]: async () => {
      await localforage.clear();
    }
  },


  modules: {
    marketArea: marketAreaModule,
    marketAreaOverview: marketAreaOverviewModule,
  }

});

store.dispatch(RootActions.getPrices);

export default store;
