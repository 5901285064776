export class Token {
  public readonly audience: string[];
  public readonly iss: string;
  public readonly id: string;
  public readonly permissions: string[];
  public readonly roles: string[];
  public readonly scope: string[];
  public readonly customer: number;
  public readonly email: string;

  constructor(token: any) {
    this.audience = token.aud;
    this.iss = token.iss;
    this.id = token.sub;
    this.permissions = token.permissions;
    this.roles = token["https://iib-institut.de/roles"];
    this.scope = token.scope?.split(" ");
    this.customer = token["https://iib-it.de/customer"];
    this.email = token["https://iib-it.de/email"];
  }
}
