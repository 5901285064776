import { LatLngBounds, tooltip } from "leaflet";
import Vue from "vue";
import { colors } from "vuetify/lib";
import { Color, Colors } from "vuetify/lib/util/colors";
import murmurhash from "murmurhash";
import { Configuration, MarketareaApi, MarketAreaGetDto } from "../api/types";
export const set = (property: string) => (store: any, payload: any) => {
  Vue.set(store, property, payload);
};
export const add = (property: string) => (store: any, payload: any) =>
  store[property].push(payload);

export const timeout = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const latLngBoundsToBBox = (bounds: LatLngBounds): GeoJSON.BBox => {
  return [
    bounds.getNorthWest().lat,
    bounds.getNorthWest().lng,
    bounds.getSouthEast().lat,
    bounds.getSouthEast().lng,
  ] as GeoJSON.BBox;
};

export const getHashedInt = (text: string, max: number) => {
  let hash = murmurhash(text, 0) / 10000000000;
  if (hash < 0.1) {
    hash *= 10;
  }
  return Math.floor(hash * max);
};

export const getRandomMaterialColorFromText = (text: string): Color => {
  const colorKeys = getTypedKeys(colors);
  const color = colors[colorKeys[getHashedInt(text, colorKeys.length)]];
  const shadeKeys = getTypedKeys(color);
  return (color as any).base;
};

export function getTypedKeys<T>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof typeof obj>;
}

export const calculateMarketAreaIndices = (
  marketArea: MarketAreaGetDto,
  groupIdx: number,
  areaIdx: number,
): string => {
  let tooltip = "";
  if (marketArea.marketAreaGroups.length > 1 && groupIdx > 0) {
    const defaultGroup = marketArea.marketAreaGroups.find(
      group => group.isDefault,
    );
    tooltip = `${groupIdx + (defaultGroup?.selectedAreas?.length || 0)}.`;
  }
  tooltip += `${areaIdx + 1}`;
  return tooltip;
};